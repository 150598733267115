.header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 60px;
    border-bottom: 1px solid rgb(218, 218, 218);
    margin-right: 30px;
    /* background-color: #1976d2; */
  }


  .header2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    border-bottom: 1px solid rgb(218, 218, 218);
    padding-left: 20px;
    font-weight: bold;
    font-size:x-large;
    color: rgb(29, 28, 28);
    margin-top: 5px;
    /* background-color: #1976d2; */
  }

  .logo {
    width: 70px;
    height: 70px;
  }
  
  .login {
    border: 1px solid rgb(20, 20, 20);
    padding: 12px 30px;
    border-radius: 50px;
    background-color: #161515;
    color: rgb(231, 225, 225);
    cursor: pointer;
    font-size: 15px;
    outline: none;
    margin-right: 20px;
    font-weight: bold;
    /* font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; */
  }

  .login:hover {
    background-color: rgb(48, 48, 48);
  }