.pageLayout {
    margin-top: 60px;
}  

.button {
    border: 1px solid rgb(20, 20, 20);
    padding: 5px 15px;
    border-radius: 50px;
    background-color: #161515;
    color: rgb(231, 225, 225);
    cursor: pointer;
    font-size: 15px;
    outline: none;
    font-weight: bold;
    margin-bottom: 5px;
    /* font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; */
}

.selectButton {
    border: 1px solid rgb(20, 20, 20);
    padding: 5px 15px;
    border-radius: 50px;
    background-color: transparent;
    color: rgb(117, 116, 116);
    cursor: pointer;
    font-size: 15px;
    outline: none;
    font-weight: bold;
    margin-bottom: 5px;
    /* font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; */
}