@media print {
  @page {
    size: auto;
    margin: 0;
  }

  body, html {
    height: 100%;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
  }

  body * {
    visibility: hidden;
  }

  .ticket-container, .ticket-container * {
    visibility: visible;
  }

  .ticket-container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 80mm;
    height: auto;
    max-height: 100%;
    page-break-after: avoid;
    page-break-before: avoid;
    page-break-inside: avoid;
  }

  .ticket {
    width: 100% !important;
    height: auto !important;
    overflow: visible;
    box-shadow: none !important;
  }

  .print-controls {
    display: none !important;
  }

  .ticket-title {
    color: black;
  }

  #chatbot-button,
  .chatbot-button,
  [data-testid="chatbot-button"],
  [aria-label="Open chatbot"],
  .intercom-launcher-frame,
  #intercom-container {
    display: none !important;
    visibility: hidden !important;
  }
}

/* Non-print styles */
.ticket-container {
  width: 100%;
  max-width: 80mm;
  margin: auto;
}

.ticket {
  width: 100%;
}