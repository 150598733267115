@media print {
    @page {
      size: auto;
      margin: 0;
    }
  
    body, html {
      height: 100%;
      margin: 0 !important;
      padding: 0 !important;
      overflow: hidden;
    }
  
    body * {
      visibility: hidden;
    }
  
    .chart-container, .chart-container * {
      visibility: visible;
    }
  
    .chart-container {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 80mm;
      height: auto;
      max-height: 100%;
      page-break-after: avoid;
      page-break-before: avoid;
      page-break-inside: avoid;
    }
  
    .chart {
      width: 100% !important;
      height: auto !important;
      overflow: visible;
      box-shadow: none !important;
    }
  
    .print-controls {
      display: none !important;
    }
  
    .chart-title {
      color: black;
    }
  
    #chatbot-button,
    .chatbot-button,
    [data-testid="chatbot-button"],
    [aria-label="Open chatbot"],
    .intercom-launcher-frame,
    #intercom-container {
      display: none !important;
      visibility: hidden !important;
    }
  }
  
  /* Non-print styles */
  .chart-container {
    width: 100%;
    margin: auto;
  }
  
  .chart {
    width: 100%;
  }